import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../../FormInput/FormInput';
import useFeatureConfiguration from '../../../hooks/useFeatureConfiguration';

function LoginModalContent({
  handleSubmit,
  handleChange,
  email,
  password,
  errors,
  success,
  signButtonClick
}) {
  const config = useFeatureConfiguration();
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {config?.logInModalTitle || 'Please Log In'}
        </h5>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="modal-body">
          <form noValidate className="needs-validation" onSubmit={handleSubmit}>
            <div className="mb-3">
              <FormInput
                label="Email*"
                inputType="text"
                inputID="email"
                placeholder="you@example.com"
                value={email}
                required={true}
                onChange={handleChange}
                invalidFeedback="Please enter your email"
                errors={errors}
                success={success}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label="Password*"
                inputType="password"
                inputID="password"
                placeholder=""
                value={password}
                required={true}
                onChange={handleChange}
                invalidFeedback="Please enter a password that is at least 8 characters long with at least one letter and one number"
                errors={errors}
                success={success}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-dark"
          data-bs-dismiss="modal"
        >
          {config?.modalsCloseButton || 'Close'}
        </button>
        <button
          type="button"
          onClick={signButtonClick}
          className="btn btn-monarc-orange"
          data-testid="signin-btn"
        >
          {config?.confirmAccountOwnershipModalLogIn || 'Log In'}
        </button>
      </div>
    </div>
  );
}

LoginModalContent.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  email: PropTypes.string,
  password: PropTypes.string,
  errors: PropTypes.any,
  success: PropTypes.any,
  signButtonClick: PropTypes.func
};

export default LoginModalContent;
