import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import * as providerActions from '../actions/providers';
import * as userActions from '../actions/user';
import * as recordActions from '../actions/records';
import Nav from './Nav';
import { redirectUser } from '../utils/OneVueAPI.js';
import Onboarding from './containers/Onboarding';
import SHRLoading from '../Components/Providers/SHRLoading.js';
import SideMenu from './SideMenu/SideMenu';
import DashboardRestricted from './DashboardRestricted.js';

class Dashboard extends Component {
  state = {
    page: '',
    type: '',
    unsignedAuths: [],
    signedAuths: [],
    shrStatus: null,
    showSideMenuMobile: false,
    shouldRedirect: false
  };

  componentDidMount = () => {
    const { profileSession } = this.props;
    this.mounted = true;
    if (!profileSession) redirectUser(this.props);
    this.setPageParam();
    this.loadConsents();
    this.loadProfile();
    this.loadSHRStatus();
    document.body.style.backgroundColor = 'rgb(250,250,249)';
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  componentWillUnmount = () => {
    this.mounted = false;
    document.body.style.backgroundColor = null;
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.page !== this.props.match.params.page ||
      prevProps.match.params.type !== this.props.match.params.type
    ) {
      this.setPageParam();
    }
    if (prevProps.shrStatus !== this.props.shrStatus) {
      this.setState({ shrStatus: this.props.shrStatus });
    }
    if (prevProps.consents !== this.props.consents) {
      let unsigned = [];
      let signed = [];
      this.props.consents.map(consent => {
        if (consent.signed === false) {
          unsigned.push(consent);
        } else {
          signed.push(consent);
        }
        return null;
      });
      this.setState({
        unsignedAuths: unsigned,
        signedAuths: signed
      });
    }
  }
  loadSHRStatus = () => {
    this.props.userActions.getSHRStatus(this.props.authToken);
  };

  loadProfile = () => {
    const { authToken, userActions } = this.props;
    userActions.getProfile(authToken);
  };
  loadConsents = () => {
    const { authToken, userActions } = this.props;
    userActions.getConsents(authToken);
  };
  toggleSideMenuMobile = () => {
    this.setState({ showSideMenuMobile: !this.state.showSideMenuMobile });
  };

  setPageParam = () => {
    const { profileSession, match } = this.props;
    const { page, type, id } = match.params;
    let pageParam = page;
    let typeParam = type;

    if (pageParam === undefined) {
      pageParam = 'home';
    }
    if (pageParam === 'shr' && id && id !== 'record') {
      typeParam = id;
      pageParam = 'record_detail';
    }
    if (pageParam === 'patients' && id && id !== 'providers') {
      if (typeParam) pageParam = 'record_detail';
      typeParam = id;
    }

    if (profileSession.user.userType !== 'P') {
      if (pageParam === 'authorizations') {
        this.setState({
          page: 'restricted'
        });
      } else {
        this.setState({
          page: pageParam,
          type: typeParam
        });
      }
    } else {
      this.setState({
        page: pageParam,
        type: typeParam
      });
    }
  };
  getView = props => ({
    loading: <SHRLoading {...props} />,
    restricted: <DashboardRestricted />,
    onboarding: <Onboarding {...props} />,
    sync: <SHRLoading {...props} />
  });
  render() {
    const dashboardView = this.getView(this.props)[this.state.page];
    return (
      <>
        {!this.state.shouldRedirect ? (
          <div>
            <Nav
              linkTo="/home"
              {...this.props}
              className="dashboard-nav"
              toggleSideMenuMobile={this.toggleSideMenuMobile}
            />
            <div className="container-fluid dashboard-container">
              <div className="row">
                <SideMenu
                  isMobile={this.state.showSideMenuMobile}
                  url={this.props.match.url}
                  unsignedAuthsCount={this.state.unsignedAuths.length}
                  shrStatus={this.state.shrStatus}
                  toggleSideMenuMobile={this.toggleSideMenuMobile}
                  {...this.props}
                />
                <div className="mt-4 col-md-9 dashboard-container py-4">
                  {dashboardView}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/404" />
        )}
      </>
    );
  }
}

Dashboard.propTypes = {
  authToken: PropTypes.any,
  profileSession: PropTypes.any,
  userActions: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  shrStatus: PropTypes.any,
  consents: PropTypes.any
};

function mapStateToProps({
  auth: { authToken, profileSession },
  user: {
    consents: { consents },
    shrStatus
  },
  providers: { providers, isLoading }
}) {
  return {
    authToken,
    profileSession,
    providers,
    consents,
    shrStatus,
    isLoadingProviders: isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    providerActions: bindActionCreators(providerActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    recordActions: bindActionCreators(recordActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
