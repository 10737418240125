import { useState } from 'react';
import config from '../../config/config';
import {
  validatePassword,
  validateConfirmPassword
} from '../../utils/validations';

const validations = {
  password: validatePassword,
  confirmPassword: validateConfirmPassword
};

export default function useResetPasswordData() {
  const [formData, setFormData] = useState({
    password: config.PASSWORD || '',
    confirmPassword: config.PASSWORD || ''
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  const handleChange = ({ target: { id, value } }) => {
    setFormData(current => ({ ...current, [id]: value }));
    if (!validations[id] || validations[id](value, formData)) {
      setSuccess(current => ({ ...current, [id]: true }));
      setErrors(current => ({ ...current, [id]: false }));
    } else {
      setErrors(current => ({ ...current, [id]: true }));
    }
  };

  return { formData, errors, success, handleChange };
}
