import styled from 'styled-components';

type GapProps = {
  $gap: number;
};

export const VerticalGap = styled.div<GapProps>`
  height: ${({ $gap }) => $gap}px;
  width: 0px;
`;
