import styled from 'styled-components';

export const AlertTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const AlertMessage = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const FormTitle = styled.h3`
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
`;

export const FormDescription = styled.p`
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
`;
