import React, { useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/FormInput';

import { RouteChildrenProps } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import {
  ErrorAlert,
  FormAlertContainer,
  FormContainer
} from '../atoms/containers';
import { FormDescription, FormTitle } from '../atoms/texts';
import { DismissAlertBtn, FormSubmitBtn } from '../atoms/buttons';
import { FormLogo } from '../atoms/images';
import { VerticalGap } from '../atoms/spacing';
import { PasswordStrength, Rule } from '../molecules/PasswordStrength';
import zxcvbn from 'zxcvbn';
import { useCreatePasswordSubmissionMutation } from '@src/ts/services/api';

const SuccessIcon = styled.img`
  margin-bottom: 32px;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  font-weight: 700;
  text-align: center;
`;

export const CreatePasswordPage = ({
  history,
  match
}: RouteChildrenProps<{ token: string }>) => {
  const [password, setPassword] = useState('');

  const [
    submitCreatePassword,
    { isError: error, isLoading: loading, isSuccess: success, reset: clear }
  ] = useCreatePasswordSubmissionMutation();

  const {
    screenAndPagesContentConfiguration: {
      passwordMgtContentConfiguration: config
    } = {},
    mainLogo,
    companyName
  } = useTheme();

  const handleDidClickSavePassword = useCallback(() => {
    submitCreatePassword({ password, token: `${match?.params?.token}` });
  }, [password, match?.params?.token, submitCreatePassword]);

  const handleGoToPortal = useCallback(() => {
    history?.replace('/login');
    clear();
  }, [clear, history]);

  const handleChangePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  const rules: Rule[] = useMemo(() => {
    const result = zxcvbn(password);
    return [
      {
        name: 'min_length',
        validate: password => password.length >= 8,
        message:
          config?.passwordLengthRequirementMessage || 'At least 8 characters'
      },
      {
        name: 'strength',
        validate: () => result.score >= 2,
        message:
          config?.passwordStrengthRequirementMessage ||
          'At least ‘Fair’ strength'
      }
    ];
  }, [
    password,
    config?.passwordStrengthRequirementMessage,
    config?.passwordLengthRequirementMessage
  ]);

  const validated = useMemo(() => {
    let v = true;
    rules.forEach(rule => {
      if (!rule.validate(password)) {
        v = false;
      }
    });
    return v;
  }, [password, rules]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter' || !validated) return;
      handleDidClickSavePassword();
    },
    [handleDidClickSavePassword, validated]
  );

  return (
    <UnauthenticatedFormContainer>
      <FormAlertContainer>
        {error && !success && (
          <ErrorAlert
            role="alert"
            data-cy="error-alert"
            data-testid="error-alert"
          >
            {'Something went wrong. Please try again later.'}
            <DismissAlertBtn
              type="button"
              className="close"
              onClick={clear}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </DismissAlertBtn>
          </ErrorAlert>
        )}
      </FormAlertContainer>
      <FormLogo alt={mainLogo.alt} src={mainLogo.src} />
      <FormContainer>
        {success ? (
          <SuccessContainer data-testid="success-container">
            {config?.resetPasswordImage ? (
              <img
                src={config.resetPasswordImage.src}
                width={config.resetPasswordImage.width}
                height={config.resetPasswordImage.height}
                alt="success"
              />
            ) : (
              <SuccessIcon
                src={'/portal/lockCheck.svg'}
                width={100}
                height={104}
              />
            )}
            {config?.resetPasswordSuccessMessage ||
              'You successfully created your password!\nYou are now signed in.'}
          </SuccessContainer>
        ) : (
          <>
            <FormTitle>
              {config?.createPasswordTitle || 'Create password'}
            </FormTitle>
            <FormDescription>
              {config?.createPasswordSubtitle ||
                `Create a password to complete the registration process for ${companyName}.\nYou’ll use this password to log into your portal.`}
            </FormDescription>
            <VerticalGap $gap={24} />
            <FormInput
              maxLength={64}
              label={config?.createPasswordPasswordField?.label || 'Password'}
              showLabel={config?.createPasswordPasswordField?.showLabel}
              hideLabel={config?.createPasswordPasswordField?.hideLabel}
              inputType="password"
              inputID="password"
              data-testid="password-input"
              value={password}
              placeholder={
                config?.createPasswordPasswordField?.placeholder || ''
              }
              required={true}
              onChange={handleChangePassword}
              onKeyUp={handleKeyUp}
            />
            {password && (
              <>
                <VerticalGap $gap={16} />
                <PasswordStrength password={password} rules={rules} />
              </>
            )}
          </>
        )}
      </FormContainer>
      {success ? (
        <FormSubmitBtn
          data-testid="password-saved-proceed-btn"
          onClick={handleGoToPortal}
          type="button"
        >
          {config?.createPasswordSuccessButtonLabel || 'Go to Portal'}
        </FormSubmitBtn>
      ) : (
        <FormSubmitBtn
          data-testid="create-password-submit-btn"
          onClick={handleDidClickSavePassword}
          type="button"
          disabled={!validated}
        >
          {loading ? (
            <i className="fas fa-circle-notch fa-spin" />
          ) : (
            config?.createPasswordButtonLabel || 'Save password'
          )}
        </FormSubmitBtn>
      )}
    </UnauthenticatedFormContainer>
  );
};
