import {
  configureStore,
  createImmutableStateInvariantMiddleware
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  createMigrate,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory as createHistory } from 'history';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from 'react-router-redux';
import StateLoader from '../reducers/StateLoader';
import rootReducer from '../reducers';
import api from '../ts/services/api';

const migrations = {
  0: state => {
    return {
      ...state
    };
  }
};

const persistConfig = {
  key: 'root',
  version: 0,
  storage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ['prnOptions']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const history = createHistory();

function checkIfModuleHot(store) {
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }
}

const stateLoader = new StateLoader();

const isProd = process.env.NODE_ENV === 'production';

export const store = configureStore({
  reducer: persistedReducer,
  preloadedState: stateLoader.loadState(),
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
      immutableCheck: !isProd
    }).concat(routerMiddleware(history));
    if (!isProd) {
      middleware.push(createImmutableStateInvariantMiddleware());
    }
    middleware.push(api.middleware);
    return middleware;
  },
  devTools: !isProd
});
checkIfModuleHot(store);

export const persistor = persistStore(store);

store.subscribe(() => {
  const state = store.getState();

  if (state.auth.authToken && state.auth.profileSession) {
    stateLoader.saveState(store.getState());
  }
});
