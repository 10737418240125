import React from 'react';
import styled from 'styled-components';

const getProgressColor = (value: number, max: number) => {
  let progress = value / max;
  if (progress <= 0.25) {
    return '#FFB3AF';
  }
  if (progress <= 0.5) {
    return '#FBD679';
  }
  if (progress < 0.75) {
    return '#73E2BC';
  }
  return '#026E4F';
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #fff;
`;

type FillProps = { $value: number; $max: number };

const Fill = styled.div<FillProps>`
  height: 100%;
  border-radius: 2px;
  width: ${({ $value, $max }) => `${($value / $max) * 100}%`};
  background-color: ${({ $value, $max }) => getProgressColor($value, $max)};
`;

type ProgressProps = { value: number; max: number };

export const Progress = ({ value, max }: ProgressProps) => {
  return (
    <Container>
      <Fill $value={value} $max={max} />
    </Container>
  );
};
