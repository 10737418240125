import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
class ResourceItem extends Component {
  sendGAEvent = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Resource ' + this.props.entry.fields.url
    });
  };
  render() {
    const date = new Date(this.props.entry.fields.date).toDateString();
    return (
      <a
        href={this.props.entry.fields.url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={this.sendGAEvent}
        className="list-group-item list-group-item-action flex-column align-items-start"
      >
        <div
          className={`container resources ${
            this.props.entry.fields.featured ? `featured` : ''
          } `}
        >
          <li className="media">
            <div className="media-img">
              <img
                src={
                  this.props.entry.fields.image
                    ? this.props.entry.fields.image.fields.file.url
                    : '/News icon.jpeg'
                }
                className="rounded img-fluid"
                alt=""
              />
            </div>
            <div className="media-body">
              <h3 className="mt-0 mb-1">{this.props.entry.fields.title}</h3>
              <span>{this.props.entry.fields.category}</span>
              <span>Posted on: {date}</span>
              <h4>{this.props.entry.fields.newsdescription}</h4>
            </div>
          </li>
        </div>
      </a>
    );
  }
}
ResourceItem.propTypes = {
  entry: PropTypes.any
};
export default ResourceItem;
