import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormInput from '../FormInput/FormInput.js';
import Nav from '../Nav';
import { useTheme } from '../ThemeProvider.js';
import useForgotPasswordData from './useForgotPasswordData.js';
import useForgotPasswordSubmission from './useForgotPasswordSubmission.js';
import LoadingSpinner from '../shared/LoadingSpinner.js';

const outerStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '40px',
  top: '0%',
  position: 'absolute'
};

const formSignin = {
  width: '100%',
  maxWidth: '330px',
  padding: '15px'
};

function ForgotPassword(props) {
  const {
    theme: {
      screenAndPagesContentConfiguration: {
        passwordMgtContentConfiguration: config
      } = {}
    }
  } = useTheme();
  const { email, error, success, handleChange } = useForgotPasswordData(
    props.location
  );
  const {
    forgotPasswordSubmit,
    clear: clearSubmit,
    isLoading,
    didLoad,
    data: submitResult,
    error: submitError
  } = useForgotPasswordSubmission();

  const handleClick = event => {
    event.preventDefault();
    clearSubmit();
    if (email && !error) {
      forgotPasswordSubmit(email);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = 'rgb(245, 245, 245)';
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);

  return (
    <>
      <Nav isLoggedIn={false} {...props} />
      <div className="text-center forgot-container" style={{ ...outerStyle }}>
        <form className="form-signin" style={formSignin}>
          <h1 className="h3 mb-3 font-weight-normal">
            {config?.forgotPasswordTitle ||
              'Enter in your email address to reset your password'}
          </h1>
          <div className="mb-3 text-left">
            <FormInput
              data-testid="form-email-input"
              label={config?.forgotPasswordEmailField?.label || 'Email*'}
              inputType="text"
              inputID="email"
              placeholder={
                config?.forgotPasswordEmailField?.placeholder ||
                'you@example.com'
              }
              value={email}
              required={true}
              onChange={handleChange}
              invalidFeedback={
                config?.forgotPasswordEmailField?.errorMessage ||
                'Please enter your email'
              }
              errors={{ email: error }}
              success={{ email: success }}
            />
          </div>
          <div className="mb-3">
            {isLoading ? <LoadingSpinner /> : null}
            {didLoad && submitResult?.success ? (
              <div
                data-cy="reset-success"
                data-testid="reset-success"
                className="alert alert-success"
                role="alert"
              >
                {config?.forgotPasswordSuccessMessage ||
                  'Please check your email for instructions on resetting your password.'}
              </div>
            ) : null}
            {(didLoad && !submitResult?.success) || submitError ? (
              <div
                data-cy="error-alert"
                data-testid="error-alert"
                className="alert alert-danger"
                role="alert"
              >
                {config?.forgotPasswordFailureMessage ||
                  submitError ||
                  'An error occurred. Make sure you are using the correct email address and try again.'}
              </div>
            ) : null}
          </div>
          <br />
          <button
            data-testid="form-submit-btn"
            data-cy="reset-password-btn"
            className="btn btn-lg btn-monarc-orange btn-block"
            onClick={handleClick}
            type="submit"
            disabled={isLoading}
          >
            {config?.forgotPasswordButtonLabel || 'Reset Password'}
          </button>
        </form>
      </div>
    </>
  );
}

ForgotPassword.propTypes = {
  location: PropTypes.any
};

export default ForgotPassword;
