import styled from 'styled-components';

export const FormPageContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  flex: 1;
  min-height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const ErrorAlert = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.border.negative};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.background.negative};
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 16px 24px;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.sm};
`;

export const InfoAlert = styled.div`
  border: 2px solid #a5c8ff;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.action.hover.secondary};
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 16px 24px;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.sm};
`;

type FormContainerProps = {
  $maxWidth?: string;
};

export const FormContainer = styled.div<FormContainerProps>`
  margin-top: 80px;
  max-width: ${({ $maxWidth }) => $maxWidth || '400px'};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormAlertContainer = styled.div`
  margin-bottom: 20px;
  min-height: 70px;
  max-width: 620px;
`;
