import React, { Component } from 'react';
import PropTypes from 'prop-types';
class ProfileAlert extends Component {
  render() {
    let title = '';

    if (this.props.type === 'alert-warning') {
      title = 'Error: ';
    } else if (this.props.title === 'updateProfile') {
      title = 'Profile Updated ';
    } else if (this.props.title === 'changePassword') {
      title = 'Password Updated ';
    }
    return (
      <div
        className={'alert alert-dismissible fade show ' + this.props.type}
        role="alert"
      >
        <strong>{title}</strong> {this.props.message}
        <button type="button" className="close" onClick={this.props.closeAlert}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}
ProfileAlert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  closeAlert: PropTypes.func,
  title: PropTypes.string
};
export default ProfileAlert;
