import React from 'react';
import InputMask from 'react-input-mask';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import DateInput from './DateInput';
import PasswordInput from './PasswordInput';
import RadioInput from './RadioInput';
function getComp(inputProps) {
  const { inputType } = inputProps;

  switch (inputType) {
    case 'select':
    case 'dropdown':
      return <SelectInput {...inputProps} />;
    case 'date':
      return <DateInput {...inputProps} />;
    case 'password':
      return <PasswordInput {...inputProps} />;
    case 'radio':
      return <RadioInput {...inputProps} />;
    default:
      return <TextInput {...inputProps} />;
  }
}
function getCompWithMask(inputProps) {
  const isInvalid =
    inputProps.errors &&
    inputProps.errors[inputProps.inputID] &&
    inputProps.value;
  const isValid = inputProps.success && inputProps.success[inputProps.inputID];
  return (
    <InputMask
      alwaysShowMask={false}
      mask={inputProps.mask}
      placeholder={inputProps.placeholder}
      onChange={inputProps.onChange}
      onFocus={inputProps.onFocus}
      type="text"
      value={inputProps.value}
      className={`form-control ${isInvalid ? 'is-invalid' : ''}${
        isValid ? 'is-valid' : ''
      }`}
      id={inputProps.inputID}
    />
  );
}
function ChooseInput(inputProps) {
  let comp = getComp(inputProps);
  if (inputProps.mask) {
    comp = getCompWithMask(inputProps);
  }
  return comp;
}
export default ChooseInput;
