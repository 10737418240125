import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
class Page404 extends Component {
  backToHome = () => {
    const linkTo = !this.props.authToken ? '/login' : '/home';
    this.props.history.push(linkTo);
  };
  openSupportDialog = () => document.getElementById('HelpButton').click();

  render() {
    const whereTo = !this.props.authToken ? 'Login' : 'Home';
    return (
      <div className="page-404">
        <div className="container box-shadow">
          <h2 className="text-monarc-orange">404 Page Not Found</h2>
          <p>Sorry, we couldn{"'"}t find what you were looking for.</p>
          <div className="links-404">
            <button
              onClick={this.backToHome}
              className="back-to-home"
              data-testid="back-btn"
            >
              Back to {whereTo}
            </button>
            <button onClick={this.openSupportDialog} data-testid="contact-btn">
              Contact Support
            </button>
          </div>
        </div>
      </div>
    );
  }
}
Page404.propTypes = {
  history: PropTypes.object,
  authToken: PropTypes.string
};

function mapStateToProps({ auth }) {
  return {
    authToken: auth.authToken
  };
}

export default connect(mapStateToProps)(Page404);
