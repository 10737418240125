import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PencilIcon from 'mdi-react/PencilIcon';
import ProvidersTable from '../shared/ProvidersTable';
import * as providerActions from '../../actions/providers';
import { startGreenlightWorkflow } from '../../utils/OneVueAPI.js';
import { ThemeConsumer } from '../ThemeProvider';

class DashboardMyProviders extends Component {
  state = {
    greenlightWorkflowUrl: '',
    isProvidersLoading: true
  };
  createButton = response => {
    if (response?.greenlightWorkflowUrl && this.mounted) {
      this.setState({
        greenlightWorkflowUrl: response.greenlightWorkflowUrl
      });
    }
  };
  componentDidUpdate = oldProps => {
    if (this.state.isProvidersLoading) {
      const { profileSession, providerActions, authToken } = this.props;
      providerActions.getProviders(authToken, profileSession.user.id);

      if (this.mounted) this.setState({ isProvidersLoading: false });
    }

    if (
      oldProps.providers.length === 0 &&
      this.props.providers.length === 0 &&
      !this.props.isLoading
    ) {
      this.props.history.push('/home/onboarding');
    }
  };
  componentWillUnmount = () => {
    this.mounted = false;
  };
  componentDidMount = () => {
    this.mounted = true;
    setTimeout(() => {
      startGreenlightWorkflow(this.createButton);
    }, 500);
  };
  clickGreenlight = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Start Greenlight Workflow Button'
    });

    window.location.replace(this.state.greenlightWorkflowUrl);
  };
  render() {
    return (
      <ThemeConsumer>
        {({
          theme: {
            smallLogo: { src, alt }
          }
        }) => (
          <div className="providers-container dashboard-item">
            <div
              className="d-flex flex-column p-3"
              style={{ background: '#fff' }}
            >
              <div className="provider-list">
                <div
                  className="dashboard-item-banner"
                  data-cy="my-providers-header"
                >
                  <h2 style={{ color: 'white' }}>My Providers</h2>

                  {this.state.greenlightWorkflowUrl ? (
                    <div className="add-provider filter">
                      <button
                        data-testid="geenlight-btn"
                        data-cy="add-provider"
                        onClick={this.clickGreenlight}
                      >
                        <PencilIcon size={20} color="#ffffff" /> Manage
                        Providers
                      </button>
                    </div>
                  ) : (
                    <div className="add-provider">
                      <i className="fas fa-circle-notch fa-spin" />
                    </div>
                  )}
                </div>
                {this.state.isProvidersLoading ? (
                  <div className="records-container-loading">
                    <picture>
                      <img
                        className="mb-4 animated infinite pulse "
                        src={src}
                        alt={alt}
                        height="72"
                        style={{ objectFit: 'contain' }}
                      />
                    </picture>
                  </div>
                ) : (
                  <ProvidersTable
                    history={this.props.history}
                    jsonData={this.props.providers}
                    isLoading={
                      this.state.isProvidersLoading || this.props.isLoading
                    }
                    {...this.state}
                    {...this.props}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

DashboardMyProviders.propTypes = {
  authToken: PropTypes.string,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  profileSession: PropTypes.object,
  providerActions: PropTypes.object,
  providers: PropTypes.array
};
function mapStateToProps(props) {
  const { auth, providers } = props;

  return {
    providers: providers.providers,
    isLoading: providers.isLoading,
    authToken: auth.authToken,
    profileSession: auth.profileSession
  };
}
function mapDispatchToProps(dispatch) {
  return {
    providerActions: bindActionCreators(providerActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardMyProviders);
