import {
  BaseQueryApi,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';

import config from '../../config/config.json';
import { Study } from '../models';

const { BASE_URL } = config;

export function prepareHeaders(
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) {
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  // temporary until RootState is created
  const authToken = (api.getState() as {
    auth: { authToken: string };
  })?.auth?.authToken;
  if (authToken) headers.set('Session-Key', authToken);
}

type SuccessfulResponse<T, Key extends string> = {
  [key in Key]: T;
} & {
  success: true;
  error?: never;
  message?: never;
};
type UnsuccessfulResponse = {
  success: false;
  error?: string;
  message?: string;
};
type Response<T, Key extends string> =
  | SuccessfulResponse<T, Key>
  | UnsuccessfulResponse;

const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders
  }),
  endpoints: builder => ({
    getAssignedStudies: builder.query<Response<Study[], 'studies'>, void>({
      query: () => 'studies/assigned'
    }),
    getStudies: builder.query<Response<Study[], 'studies'>, void>({
      query: () => 'studies'
    }),
    createPasswordSubmission: builder.mutation({
      query: formData => ({
        url: 'createPassword',
        method: 'POST',
        body: formData
      })
    })
  })
});

export default api;
export const {
  useCreatePasswordSubmissionMutation,
  useGetAssignedStudiesQuery,
  useGetStudiesQuery
} = api;
