import React, { useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import Footer from './ts/components/molecules/Footer';
import { ThemeProvider } from './Components/ThemeProvider';
import Navigation from './navigation';
import config from './config/config';

export default function Root() {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_TAG_ID);
  }, []);

  const params = new URLSearchParams(location.search);
  const isPortal = location.pathname.startsWith('/portal');
  const mainWrapperStyles = {
    paddingBottom: '0',
    height: 'calc(100vh - 65px)',
    overflow: 'auto'
  };
  if (isPortal) {
    mainWrapperStyles.height = '100vh';
  }
  return (
    <ThemeProvider>
      <BrowserRouter>
        <CompatRouter>
          <div
            id="mainContainer"
            className={
              params.has('mobileAppDisplay') ? 'mobile-app-transform' : ''
            }
          >
            <div
              ref={scrollContainerRef}
              className="container-fluid px-0 main"
              style={mainWrapperStyles}
            >
              <Navigation scrollContainerRef={scrollContainerRef} />
            </div>
            {!isPortal ? <Footer /> : null}
          </div>
        </CompatRouter>
      </BrowserRouter>
    </ThemeProvider>
  );
}
