import styled from 'styled-components';

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ToggleVisibilityBtn = styled.button`
  margin: 0;
  border: 0;
  padding: 0;
  box-shadow: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.primaryColor};
`;
