/* eslint-disable complexity */
export const MEDICATIONS = 'MEDICATIONS';
export const CONDITIONS = 'CONDITIONS';
export const IMMUNIZATIONS = 'IMMUNIZATIONS';
export const VITALS = 'VITAL_SIGNS';
export const LABS_AND_TEST_RESULTS = 'RESULTS';
export const PROCEDURES = 'PROCEDURES';
export const ALERTS = 'ALERTS';
export const APPOINTMENTS = 'APPOINTMENTS';
export const IMAGING = 'IMAGING';
export const GENETIC_TESTING = 'GENETIC_TESTING';
export const OFFICE_VISIT = 'OFFICE_VISIT';
export const ALLERGIES = 'ALLERGIES';
export const HOSPITALIZATION = 'HOSPITALIZATION';
export const PATHOLOGY = 'PATHOLOGY';
export const DEMOGRAPHICS = 'DEMOGRAPHICS';

export function getDisplayText(recordType) {
  switch (recordType) {
    case MEDICATIONS: {
      return 'Medications';
    }
    case CONDITIONS: {
      return 'Conditions';
    }
    case IMMUNIZATIONS: {
      return 'Immunizations';
    }
    case VITALS: {
      return 'Vitals';
    }
    case LABS_AND_TEST_RESULTS: {
      return 'Labs and Test Results';
    }
    case PROCEDURES: {
      return 'Procedures';
    }
    case ALERTS: {
      return 'Alerts';
    }
    case APPOINTMENTS: {
      return 'Appointments';
    }
    case IMAGING: {
      return 'Imaging';
    }
    case GENETIC_TESTING: {
      return 'Genetic Testing';
    }
    case OFFICE_VISIT: {
      return 'Office Visit';
    }
    case ALLERGIES: {
      return 'Allergies';
    }
    case HOSPITALIZATION: {
      return 'Hospitalization';
    }
    case PATHOLOGY: {
      return 'Pathology';
    }
    case DEMOGRAPHICS: {
      return 'Demographics';
    }
    default:
      return '';
  }
}
