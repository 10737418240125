import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import NoAuthPagesWrapper from '../NoAuthPagesWrapper';
import { useTheme } from '../ThemeProvider';
import FormInput from '../FormInput/FormInput';
import useResetPasswordData from './useResetPasswordData';
import useResetPasswordSubmission from './useResetPasswordSubmission';
import DismissibleAlert from './DismissibleAlert';

const defaultErrorMessage =
  'There was an error resetting your password, please make sure it is at least 8 characters long with at least 1 number, one uppercase letter and one lowercase letter.';

function ResetPassword(props) {
  const {
    theme: {
      screenAndPagesContentConfiguration: {
        sessionContentConfiguration: sessionConfig,
        passwordMgtContentConfiguration: config
      } = {}
    }
  } = useTheme();
  const { formData, errors, success, handleChange } = useResetPasswordData();
  const {
    didLoad,
    isLoading,
    data,
    error,
    clear,
    resetPasswordSubmit
  } = useResetPasswordSubmission(props.match?.params?.token);
  const handleSubmit = useCallback(() => {
    if (isLoading) return;
    resetPasswordSubmit(formData);
  }, [isLoading, formData, resetPasswordSubmit]);
  return (
    <NoAuthPagesWrapper {...props}>
      <div>
        {error ? (
          <DismissibleAlert
            type="danger"
            content={config?.resetPasswordFailureMessage || defaultErrorMessage}
            onDismiss={clear}
          />
        ) : null}
        {didLoad && data?.success ? (
          <DismissibleAlert
            type="success"
            content={
              <>
                {config?.resetPasswordSuccessMessage ||
                  'Your password has been reset, please '}
                <a href="/login">
                  <b>{sessionConfig?.navBarLogInButton || 'Sign In'}</b>
                </a>
                {/**/}.
              </>
            }
            onDismiss={clear}
          />
        ) : null}
        <h1 className="h3 mb-3 font-weight-normal">
          {config?.resetPasswordTitle || 'Reset Password'}
        </h1>
        <p>{config?.resetPasswordSubtitle || 'Enter in your new password.'}</p>
        <div className="mb-3 text-left">
          <FormInput
            data-testid="form-password-input"
            label={config?.resetPasswordPasswordField?.label || 'Password*'}
            inputType="password"
            inputID="password"
            placeholder={config?.resetPasswordPasswordField?.placeholder || ''}
            value={formData.password}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.resetPasswordPasswordField?.errorMessage ||
              'Please enter a password that is at least 8 characters long with at least one uppercase letter, one lowercase letter, and one number'
            }
            errors={errors}
            success={success}
          />
          <div className="mb-3" />
          <FormInput
            data-testid="form-confirmPassword-input"
            label={
              config?.resetPasswordConfirmPasswordField?.label ||
              'Confirm Password*'
            }
            inputType="password"
            inputID="confirmPassword"
            placeholder={
              config?.resetPasswordConfirmPasswordField?.placeholder || ''
            }
            value={formData.confirmPassword}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.resetPasswordConfirmPasswordField?.errorMessage ||
              'Please make sure your password matches'
            }
            errors={errors}
            success={success}
          />
        </div>
        <button
          data-testid="form-submit-btn"
          onClick={handleSubmit}
          className="btn btn-lg btn-outline-primary btn-block btn-monarc-orange"
          type="button"
        >
          {isLoading ? (
            <i className="fas fa-circle-notch fa-spin" />
          ) : (
            config?.resetPasswordButtonLabel || 'Reset Password'
          )}
        </button>
      </div>
    </NoAuthPagesWrapper>
  );
}

ResetPassword.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any
};

export default ResetPassword;
