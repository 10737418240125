import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { ThemeConsumer } from './ThemeProvider';

class DashboardRestricted extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <ThemeConsumer>
        {({ theme }) => (
          <div className="container" id="container">
            <div className="my-3 p-3 bg-white rounded box-shadow dashboard-item">
              <h2>Patients Only</h2>
              <hr />
              <p>
                This feature is currently only enabled for patients. We will
                notify you once this becomes enabled for all users. In the mean
                time, please share the following link with any patients you feel
                would benefit from joining the {theme?.companyName}{' '}
                {theme?.programName}.
              </p>
              <h3>
                <a href="/join">Share this link!</a>
              </h3>
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export default DashboardRestricted;
