import { useCallback, useReducer } from 'react';
import { resetPassword } from '../../utils/OneVueAPI';
import { createFetchActions } from '../../actions/utils';
import simpleReducerFactory, {
  initialState
} from '../../utils/simpleReducerFactory';

export const { types, actions } = createFetchActions('RESET_PASSWORD_SUBMIT');

export default function useResetPasswordSubmission(resetPasswordToken) {
  const [state, dispatch] = useReducer(simpleReducerFactory(types), {
    ...initialState
  });
  const resetPasswordSubmit = useCallback(
    async ({ password: newPassword, confirmPassword }) => {
      try {
        dispatch(actions.START());
        const response = await resetPassword({
          newPassword,
          confirmPassword,
          resetPasswordToken
        });
        dispatch(actions.SUCCESS(response.data));
      } catch (error) {
        dispatch(actions.FAILURE(error.message));
      }
    },
    [dispatch, resetPasswordToken]
  );
  function clear() {
    dispatch(actions.CLEAR());
  }
  return {
    resetPasswordSubmit,
    clear,
    ...state
  };
}
