import React from 'react';
import PropTypes from 'prop-types';

function DismissibleAlert({ type, content, onDismiss }) {
  return (
    <div
      data-testid={`alert-${type}`}
      className={`alert alert-${type} alert-dismissible fade show`}
      role="alert"
    >
      {content}
      <button
        data-testid="alert-dismissible-close"
        type="button"
        className="close"
        onClick={onDismiss}
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

DismissibleAlert.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default DismissibleAlert;
