export const initialState = {
  isLoading: false,
  didLoad: false,
  error: null,
  data: null
};

export default function simpleReducerFactory(types) {
  function reducer(state = { ...initialState }, action) {
    switch (action.type) {
      case types.START:
        return { ...state, isLoading: true };
      case types.SUCCESS:
        return {
          ...state,
          isLoading: false,
          didLoad: true,
          data: action.payload
        };
      case types.FAILURE:
        return { ...state, isLoading: false, error: action.payload };
      case types.CLEAR:
        return { ...initialState };
    }
  }
  return reducer;
}
