import React, { PropsWithChildren } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';

export function generateBrandThemingFromWhiteLabelTheme(theme: DefaultTheme) {
  return {
    brand: {
      color: {
        primary: theme.primaryColor,
        secondary: theme.secondaryColor
      },
      fontFamily: {
        primary: theme.primaryFont,
        secondary: theme.secondaryFont
      }
    }
  };
}

const styledTheme = {
  colors: {
    text: {
      primary: '#161c22f2', // UI Colors/Text/text-primary
      secondary: '#161c22bf', // UI Colors/Text/text-secondary
      disabled: '#161c228c', // UI Colors/Text/text-disabled
      negative: '#ac2a2f', // UI Colors/Text/text-negative
      inverse: '#ffffff' // UI Colors/Text/text-inverse
    },
    action: {
      primary: '#3c90f1', // UI Colors/Action/Primary/action-primary
      secondary: '#ffffff', // UI Colors/Action/Secondary/action-secondary
      hover: {
        primary: '#1478d5', // UI Colors/Action/Primary/action-primaryHover
        secondary: '#ebf1ff' // UI Colors/Action/Secondary/action-secondaryHover
      },
      disabled: {
        primary: '#bfc6d1', // UI Colors/Action/Primary/action-primaryDisabled
        secondary: '#e2e6ee' // UI Colors/Action/Secondary/action-secondaryDisabled
      }
    },
    border: {
      default: '#dddddd', // Borders / Default
      secondary: '#bfc6d1', // UI Colors/Border/border-secondary
      negative: '#ffb3af', // UI Colors/Border/border-negative
      warning: '#fbd679', // UI/Warning/warning-border
      positive: '#73e2bc' // UI/Positive/positive-border
    },
    surface: {
      secondary: '#F5F6FA', // UI/Surface/surface-backgroundSecondary
      background: {
        primary: '#ffffff', // UI Colors/Surface/Background/surface-backgroundPrimary
        secondary: '#f5f6fa', // UI Colors/Surface/Background/surface-backgroundSecondary
        negative: '#ffedeb', // UI/Surface/Background/surface-backgroundNegative
        active: '#eaeaea'
      }
    }
  },
  font: {
    size: {
      xxs: '0.625rem', // size/xxs
      xs: '0.75rem', // size/xs
      sm: '0.875rem', // size/sm
      md: '1rem', // size/md
      lg: '1.125rem', // size/lg
      xl: '1.25rem', // size/xl
      xxl: '1.375rem', // size/xxl
      xxxl: '1.5rem' // size/xxxl
    },
    lineHeight: {
      xs: '1rem', // line-height/xs
      sm: '1.125rem', // line-height/sm
      md: '1.25rem', // line-height/md
      lg: '1.375rem', // line-height/lg
      xl: '1.5rem', // line-height/xl
      xxl: '1.625rem', // line-height/xxl
      xxxl: '1.75rem' // line-height/xxxl
    }
  },
  typography: {
    h1: {
      size: '1.875rem',
      weight: '600',
      style: 'normal',
      lineHeight: '2rem',
      textTransform: 'capitalize'
    },
    h2: {
      size: '1.5rem',
      weight: '400',
      style: 'normal',
      lineHeight: '1.75rem',
      textTransform: 'capitalize'
    },
    h3: {
      size: '1.125rem',
      weight: '700',
      style: 'normal',
      lineHeight: '1.375rem'
    },
    h4: {
      size: '1rem',
      weight: '700',
      style: 'normal',
      lineHeight: '1.25rem',
      textTransform: 'capitalize'
    },
    body1: {
      size: '0.875rem',
      weight: '400',
      style: 'normal',
      lineHeight: '1.125rem'
    },
    body2: {
      size: '0.75rem',
      weight: '400',
      style: 'normal',
      lineHeight: '1rem',
      letterSpacing: '0.12px'
    }
  }
};

type StyledThemeProviderProps = {
  theme: DefaultTheme;
} & PropsWithChildren;
export function StyledThemeProvider({
  children,
  theme
}: StyledThemeProviderProps) {
  return (
    <ThemeProvider
      theme={{
        ...styledTheme,
        ...generateBrandThemingFromWhiteLabelTheme(theme),
        ...theme
      }}
    >
      {children}
    </ThemeProvider>
  );
}

export default styledTheme;
