import React, { useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { startGreenlightWorkflow } from '../../utils/OneVueAPI.js';
import useFeatureConfiguration from '../../hooks/useFeatureConfiguration.js';

const STATES = {
  LOADING: 'loading',
  DONE: 'done',
  TIMEOUT: 'timeout',
  ERROR: 'error'
};

function MedfusionButton({
  isProviders,
  _startGreenlightWorkflow = startGreenlightWorkflow
}) {
  const config = useFeatureConfiguration();
  const [buttonState, setButtonState] = useState(STATES.LOADING);
  const [greenlightWorkflowUrl, setGreenlightWorkflowUrl] = useState('');

  const createButton = response => {
    if (response?.greenlightWorkflowUrl) {
      setGreenlightWorkflowUrl(response.greenlightWorkflowUrl);
      setButtonState(STATES.DONE);
    } else if (response) {
      setButtonState(STATES.TIMEOUT);
    } else {
      setButtonState(STATES.ERROR);
    }
  };

  const connectGreenlight = useCallback(() => {
    _startGreenlightWorkflow(createButton);
  }, [_startGreenlightWorkflow]);

  useEffect(() => {
    connectGreenlight();
  }, [connectGreenlight]);

  const clickGreenlight = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Start Greenlight Workflow Button'
    });

    window.location.href = greenlightWorkflowUrl;
  };

  return (
    <div className="col-12 text-center">
      <h3 style={{ fontWeight: '200' }} className="m-3 import-records">
        {isProviders
          ? ''
          : config.shrIntegrationSubtitle ||
            'Import Your Electronic Health Records'}
      </h3>
      <div
        id="loading-holder"
        data-testid="loading-holder"
        className={`mb-3 ${buttonState === STATES.LOADING ? '' : 'd-none'}`}
      >
        <div className="row justify-content-center">
          <div className="fa-3x text-center">
            <i className="fas fa-circle-notch fa-spin" />
          </div>
        </div>
      </div>
      <p
        className={`mf-connect ${buttonState === STATES.DONE ? '' : 'd-none'}`}
        id="mfConnectBtn"
      >
        <button
          onClick={clickGreenlight}
          className="button mf-cta__primary mf-connect-btn"
          type="button"
          data-testid="green-light-redirect-button"
        >
          <span className="mf-icon mf-icon__medfusion-twirl mf-color__inverse" />
          {isProviders
            ? config.shrIntegrationManageButton || 'Manage Your Providers'
            : config.shrIntegrationConnectButton || 'Import Health Records'}
        </button>
      </p>
      <button
        onClick={connectGreenlight}
        id="reconnectTimedoutBtn"
        data-testid="green-light-connect-timeout-button"
        className={`btn btn-monarc-orange mx-2 ${
          buttonState === STATES.TIMEOUT ? '' : 'd-none'
        }`}
        type="button"
      >
        <span>
          <i className="fas fa-exclamation-triangle" />{' '}
          {config.shrIntegrationTimeoutButton ||
            'Oops, there was a timeout, click here to try again.'}
        </span>
      </button>
      <button
        onClick={connectGreenlight}
        data-testid="green-light-reconnect-error-button"
        id="reconnectErrorBtn"
        className={`btn btn-monarc-orange mx-2 ${
          buttonState === STATES.ERROR ? '' : 'd-none'
        }`}
        type="button"
      >
        <span>
          <i className="fas fa-exclamation-triangle" />{' '}
          {config.shrIntegrationErrorButton ||
            'Oops, there was an unexpected error, click here to try again.'}
        </span>
      </button>
    </div>
  );
}

MedfusionButton.propTypes = {
  isProviders: PropTypes.any,
  _startGreenlightWorkflow: PropTypes.func
};

export default MedfusionButton;
