import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../services/store';
import { Study } from '../models';
import api from '../services/api';

interface StudySelectorState {
  current?: Study;
}

const initialState: StudySelectorState = {};

const studySelectorSlice = createSlice({
  name: 'studySelector',
  initialState,
  reducers: {
    setSelectedStudy: (state, action: PayloadAction<Study>) => {
      state.current = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      api.endpoints.getAssignedStudies.matchFulfilled,
      (state, action) => {
        if (
          !state.current &&
          action.payload.success &&
          action.payload.studies.length
        ) {
          state.current = action.payload.studies[0];
        }
      }
    );
  }
});

export const { setSelectedStudy } = studySelectorSlice.actions;

export const getSelectedStudy = (state: RootState) =>
  state.studySelector?.current;

export default studySelectorSlice;
