import React, { ReactNode, useMemo } from 'react';
import { getYear } from 'date-fns';
import { useTheme } from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Block, Inline } from '@contentful/rich-text-types';
import { Paragraph, StyledFooter, StyledLink } from '../atoms/footer';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => (
      <Paragraph>{children}</Paragraph>
    )
  }
};

function Footer() {
  const {
    companyName,
    showSiteGeneralPolicies,
    footerParagraph,
    policies
  } = useTheme();
  const copyrightYearText = `© ${getYear(new Date()) ?? ''}`;
  const memoizedFooterParagraph = useMemo(
    () =>
      footerParagraph
        ? documentToReactComponents(footerParagraph, options)
        : null,
    [footerParagraph]
  );
  const privacyTitle = useMemo(() => {
    return (
      policies?.find?.(({ types }) => types === 'privacy')?.title ??
      'Privacy Policy'
    );
  }, [policies]);
  const termsTitle = useMemo(() => {
    return (
      policies?.find?.(({ types }) => types === 'terms')?.title ??
      'Terms of Service'
    );
  }, [policies]);
  return (
    <StyledFooter>
      {memoizedFooterParagraph}
      <Paragraph>
        {copyrightYearText} {companyName || ''}{' '}
        {showSiteGeneralPolicies ? (
          <>
            | <StyledLink to="/privacy">{privacyTitle}</StyledLink> |{' '}
            <StyledLink to="/terms">{termsTitle}</StyledLink>
          </>
        ) : null}
      </Paragraph>
    </StyledFooter>
  );
}

export default Footer;
