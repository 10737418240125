import React, { useCallback, useMemo, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { omit } from 'lodash';
import { Document } from '@contentful/rich-text-types';
import { LabelContainer, ToggleVisibilityBtn } from '../atoms/input';

type FormInputProps = {
  inputID: string;
  invalidFeedback?: React.JSX.Element | string;
  label?: any;
  description?: string | Document;
  errors?: any;
  inputType?: string;
  showLabel?: string;
  maxLength?: number;
  hideLabel?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  descriptionClassName?: string;
  key?: any;
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  onKeyUp?: any;
  pattern?: any;
  placeholder?: any;
  required?: any;
  success?: any;
  value?: any;
  'data-testid'?: string;
};

export function FormInput(props: Readonly<FormInputProps>) {
  const [hidePassword, setHidePassword] = useState(true);

  const togglePasswordVisibility = useCallback(() => {
    setHidePassword(h => !h);
  }, []);

  const {
    inputID,
    label,
    description,
    inputType,
    errors,
    invalidFeedback,
    wrapperClassName,
    labelClassName,
    descriptionClassName,
    onKeyUp,
    showLabel,
    hideLabel
  } = props;

  const input = useMemo(() => {
    const {
      inputType,
      inputID,
      errors,
      success,
      wrapperClassName,
      maxLength,
      ...rest
    } = props;
    const inputWrapperClassName = wrapperClassName ?? 'input-group';
    let className = 'form-control';
    if (errors?.[inputID] && props.value) {
      className = 'form-control is-invalid';
    }
    if (success?.[inputID]) {
      className = 'form-control is-valid';
    }
    const type = inputType === 'password' && !hidePassword ? 'text' : inputType;
    return (
      <div className={inputWrapperClassName}>
        <input
          maxLength={maxLength}
          autoComplete="on"
          className={className}
          type={type}
          id={inputID}
          onKeyUp={onKeyUp}
          data-testid={props['data-testid']}
          {...omit(rest, [
            'invalidFeedback',
            'wrapperClassName',
            'labelClassName'
          ])}
        />
      </div>
    );
  }, [props, hidePassword, onKeyUp]);

  return (
    <>
      <div className={wrapperClassName} data-testid={`form-input-${inputID}`}>
        {inputType !== 'checkbox' ? (
          <LabelContainer>
            <label className={labelClassName} htmlFor={inputID}>
              {label}
            </label>
            {inputType === 'password' ? (
              <ToggleVisibilityBtn
                type="button"
                className="btn btn-link"
                onClick={togglePasswordVisibility}
                data-testid={`toggle-visibility-${inputID}`}
              >
                {hidePassword ? showLabel ?? 'Show' : hideLabel ?? 'Hide'}
              </ToggleVisibilityBtn>
            ) : null}
          </LabelContainer>
        ) : null}
        {description ? (
          <small className={`d-block ${descriptionClassName ?? ''}`}>
            {typeof description === 'string'
              ? description
              : documentToReactComponents(description)}
          </small>
        ) : null}
        {input}
        {inputType === 'checkbox' ? (
          <label className={labelClassName} htmlFor={inputID}>
            {label}
          </label>
        ) : null}
      </div>
      {errors?.[inputID] ? (
        <div
          data-testid={`invalid-feedback-${inputID}`}
          className="invalid-feedback"
        >
          {invalidFeedback}
        </div>
      ) : null}
    </>
  );
}
