import { Link } from 'react-router-dom-v5-compat';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0 -2px 2px -2px #000000;
  color: white;
  font-size: 0.625rem;
`;

export const Paragraph = styled.p`
  margin: 0;
`;

export const StyledLink = styled(Link)`
  color: white;
  &:hover {
    color: white;
  }
`;
