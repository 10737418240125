import { useState } from 'react';
import config from '../../config/config';
import { validateEmail } from '../../utils/validations';

export default function useForgotPasswordData(location) {
  const [email, setEmail] = useState(
    location?.state?.email || config.EMAIL || ''
  );
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = ({ target: { value } }) => {
    setEmail(value);
    const validEmail = validateEmail(value);
    if (validEmail) {
      setSuccess(true);
    }
    setError(!validEmail);
  };

  return { email, error, success, handleChange };
}
