export const USER_DOCTOR = 'D';
export const USER_PATIENT = 'P';
export const USER_CAREGIVER = 'C';
export const USER_OTHER = 'O';

export const TYPE_MAPPING = {
  [USER_OTHER]: '',
  [USER_CAREGIVER]: 'Caregiver',
  [USER_PATIENT]: 'Patient'
};
