import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { joiResolver } from '@hookform/resolvers/joi';
import useScreeningQuestionsConfiguration from '../../../hooks/useScreeningQuestionsConfiguration';
import HeaderWithBackground from '../../HeaderWithBackground';
import useValidateScreening from './useValidateScreening';
import { createScreeningQuestionsFormSchema } from './schema';
import { splitFormDataByPrefix } from '../../RegisterNew/utils/mappers';
import AlertBanner from '../../AlertBanner';
import UserQuestionsForm from '../../UserQuestionsForm';
import UserInformationForm from '../../UserInformationForm';
import LoadingSpinner from '../../shared/LoadingSpinner';
import Footer from './components/Footer';
import ContentWrapper from './components/ContentWrapper';
import { pick } from 'lodash';

function ScreeningQuestions({ history, logout }) {
  const {
    userInformation,
    questions,
    configuration: {
      title,
      image,
      submitLabel,
      submitError,
      qualificationMessage,
      disqualificationMessage,
      continueLabel
    },
    isLoading: isLoadingRegistrationPage,
    error: registrationPageFetchError,
    refetch: registrationPageRefetch
  } = useScreeningQuestionsConfiguration();

  const {
    isLoading: isSubmitting,
    didLoad: validateScreeningSuccess,
    data: validateScreeningResult,
    error: validationError,
    validateScreening
  } = useValidateScreening();
  const inputIdPrefix = 'screeningQuestions_';
  const { schema } = createScreeningQuestionsFormSchema({
    screeningQuestions: questions,
    inputIdPrefix
  });
  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit: onSubmit
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schema)
  });

  const success = Object.keys(dirtyFields)
    .filter(field => !errors[field])
    .reduce((acc, field) => {
      acc[field] = dirtyFields[field];
      return acc;
    }, {});

  const handleSubmit = data => {
    const { dataWithoutPrefix, dataWithPrefix } = splitFormDataByPrefix(
      data,
      inputIdPrefix
    );
    validateScreening(
      { ...dataWithoutPrefix, screeningQuestions: dataWithPrefix },
      questions
    );
  };

  const handleContinue = () => {
    if (validateScreeningResult.success) {
      history.push('/');
    } else {
      logout();
    }
  };

  if (isLoadingRegistrationPage) {
    return (
      <ContentWrapper>
        <LoadingSpinner />
      </ContentWrapper>
    );
  }

  if (registrationPageFetchError) {
    return (
      <ContentWrapper>
        <p>{registrationPageFetchError.message}</p>
        <button onClick={registrationPageRefetch}>
          <i className="fas fa-sync" />
        </button>
      </ContentWrapper>
    );
  }

  if (validateScreeningSuccess) {
    return (
      <ContentWrapper wrapperClassName="d-flex flex-column align-items-center">
        {documentToReactComponents(
          validateScreeningResult.success
            ? qualificationMessage
            : disqualificationMessage
        )}
        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={handleContinue}
        >
          {continueLabel}
        </button>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <HeaderWithBackground
        title={title}
        backgroundImage={image}
        style={{ height: 'fit-content', paddingBlock: 48 }}
        titleStyle={{
          top: '20%',
          fontSize: 30,
          lineHeight: '38px',
          letterSpacing: 'unset',
          height: 'unset',
          width: 'unset'
        }}
      />
      {validationError ? (
        <AlertBanner
          className="mt-4"
          type="error"
          title={submitError}
          errorMessage={validationError}
        />
      ) : null}
      <UserQuestionsForm
        control={control}
        errors={errors}
        success={success}
        questions={questions}
        inputIdPrefix={inputIdPrefix}
      />
      {userInformation ? (
        <UserInformationForm
          control={control}
          errors={errors}
          success={success}
          userInformation={pick(userInformation, 'passwordField')}
        />
      ) : null}
      <Footer
        label={submitLabel}
        isDisabled={isSubmitting || !isDirty}
        onSubmit={onSubmit(handleSubmit)}
      />
    </ContentWrapper>
  );
}

ScreeningQuestions.propTypes = {
  history: PropTypes.any,
  logout: PropTypes.func,
  useScreeningQuestionsConfiguration: PropTypes.func
};

export default ScreeningQuestions;
