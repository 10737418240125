import { useReducer } from 'react';
import { forgotPassword } from '../../utils/OneVueAPI';
import { createFetchActions } from '../../actions/utils';
import simpleReducerFactory, {
  initialState
} from '../../utils/simpleReducerFactory';

export const { types, actions } = createFetchActions('FORGOT_PASSWORD_SUBMIT');

export default function useForgotPasswordSubmission() {
  const [state, dispatch] = useReducer(simpleReducerFactory(types), {
    ...initialState
  });
  async function forgotPasswordSubmit(email) {
    try {
      dispatch(actions.START());
      const response = await forgotPassword(email);
      dispatch(actions.SUCCESS(response.data));
    } catch (error) {
      dispatch(actions.FAILURE(error.message));
    }
  }
  function clear() {
    dispatch(actions.CLEAR());
  }
  return {
    forgotPasswordSubmit,
    clear,
    ...state
  };
}
