import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Modal from '../shared/Modal';

export default function PolicyModal({
  visible,
  policy,
  onClose,
  closeOnOutsideClick
}) {
  return (
    <Modal
      data-testid="policy-modal"
      show={visible}
      className="edit-modal policy-modal"
      onClose={onClose}
      closeOnOutsideClick={closeOnOutsideClick}
    >
      <div className="modal-header">
        <h4 className="modal-title" id="policyModalTitle">
          {policy.title}
        </h4>
      </div>
      <div className="modal-body policy-content">
        <ReactMarkdown linkTarget="_blank">{policy.content}</ReactMarkdown>
      </div>
      <div className="modal-footer">
        <button className="btn btn-primary" onClick={onClose}>
          <i className="fas fa-times" />
        </button>
      </div>
    </Modal>
  );
}

PolicyModal.propTypes = {
  visible: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  policy: PropTypes.any
};
