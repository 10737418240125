import React, { Component } from 'react';
import Nav from './Nav';

class EmailConfirmationNeeded extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Nav />
        <h2>Please confirm your email</h2>
      </div>
    );
  }
}

export default EmailConfirmationNeeded;
