import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer } from 'react-router-redux';
import auth from './auth';
import user from './user';
import providers from './providers';
import records from './records';
import recordsInvitations from './recordsInvitations';
import prnOptions from './prnOptions';
import cmsLocales from './cmsLocales';
import api from '../ts/services/api';
import studySelector from '../ts/slices/studySelector';

const rootReducer = combineReducers({
  auth,
  user,
  providers,
  records,
  recordsInvitations,
  routing: routerReducer,
  prnOptions,
  cmsLocales,
  [api.reducerPath]: api.reducer,
  [studySelector.reducerPath]: studySelector.reducer
});

export default rootReducer;
