import styled from 'styled-components';

export const FormSubmitBtn = styled.button`
  min-width: 160px;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: ${({ theme, disabled }) =>
    disabled ? '#BFC6D1' : theme.primaryColor};
  box-shadow: 0;
  border: 0;
  text-align: center;
  color: white;
  margin-top: 40px;
`;

export const GhostBtn = styled.button`
  min-width: 160px;
  padding: 12px 16px;
  background-color: transparent;
  box-shadow: 0;
  border: 0;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  margin-top: 8px;
`;

export const DismissAlertBtn = styled.button`
  margin: 0;
  border: 0;
  margin-left: 8px;
`;
