import styled from 'styled-components';

export const FormLogo = styled.img`
  display: flex;
  alignself: center;
  max-width: 300px;
  max-height: 100px;
  width: 100%;
  height: auto;
  object-fit: contain;
`;
