import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import config from '../../config/config';
import * as actions from '../../actions/auth.js';
import { useTheme } from '../ThemeProvider.js';
import SessionButton from './SessionButton.js';
import MobileNavBar from './MobileNavBar.js';

const navStyles = {
  backgroundColor: 'white',
  boxShadow: '1px 0 5px rgba(25,25,25,0.25)'
};

const Nav = ({
  linkTo = '/',
  profileSession,
  needsToSign,
  authToken,
  actions,
  className,
  isLanding,
  toggleSideMenuMobile
}) => {
  const { theme: { mainLogo } = {} } = useTheme();
  const handleLogOut = () => {
    actions.logout(authToken);
  };
  return (
    <>
      <MobileNavBar
        profileSession={profileSession}
        needsToSign={needsToSign}
        logout={handleLogOut}
        className={className}
        isLanding={isLanding}
        toggleSideMenuMobile={toggleSideMenuMobile}
        authToken={authToken}
      />
      <nav
        id="navbar"
        className="navbar navbar-light navbar-expand-lg sticky-top flex-md-nowrap justify-content-between desktop-nav"
        style={navStyles}
      >
        <a className="navbar-brand" href={linkTo}>
          <img
            src={mainLogo?.src}
            height="60"
            alt={mainLogo?.alt}
            style={{ objectFit: 'contain' }}
          />
        </a>
        {config.SHOW_PORTAL_LINK ? <Link to="/portal">PORTAL</Link> : null}
        <SessionButton
          profileSession={profileSession}
          needsToSign={needsToSign}
          logout={handleLogOut}
          authToken={authToken}
        />
      </nav>
    </>
  );
};

Nav.propTypes = {
  linkTo: PropTypes.string,
  profileSession: PropTypes.object,
  authToken: PropTypes.string,
  actions: PropTypes.object,
  needsToSign: PropTypes.bool,
  className: PropTypes.string,
  isLanding: PropTypes.bool,
  toggleSideMenuMobile: PropTypes.func
};

function mapStateToProps({
  auth,
  user: {
    consents: { consents, needsToSign }
  }
}) {
  return {
    authToken: auth.authToken,
    profileSession: auth.profileSession,
    consents,
    needsToSign
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
