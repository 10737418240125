import React from 'react';
import styled, { useTheme } from 'styled-components';
import zxcvbn from 'zxcvbn';
import { Progress } from './ProgressBar';

export type Rule = {
  name: string;
  // eslint-disable-next-line no-unused-vars
  validate: (password: string) => boolean;
  message: string;
};

type PasswordStrengthProps = {
  password: string;
  rules: Rule[];
};

const STRENGTH_SCORE_MAP = {
  0: 'Very weak',
  1: 'Weak',
  2: 'Fair',
  3: 'Good',
  4: 'Excellent'
};

const RulesContainer = styled.div`
  margin-top: 8px;
`;

export function PasswordStrength({ password, rules }: PasswordStrengthProps) {
  const {
    screenAndPagesContentConfiguration: {
      passwordMgtContentConfiguration: config
    } = {}
  } = useTheme();

  const result = zxcvbn(password);

  return (
    <div style={{ width: '100%' }}>
      <Progress value={result.score} max={4} />
      <RulesContainer>
        Password strength:{' '}
        <b>
          {config?.strengthScoreValues?.[result.score] ||
            STRENGTH_SCORE_MAP[result.score]}
        </b>
        {rules.map((rule, index) => (
          <div key={index}>
            <img
              src={
                rule.validate(password)
                  ? config?.requirementFulfilledIcon?.src || '/portal/done.svg'
                  : config?.requirementUnfulfilledIcon?.src ||
                    '/portal/close.svg'
              }
              alt="status"
            />{' '}
            {rule.message}
          </div>
        ))}
      </RulesContainer>
    </div>
  );
}
